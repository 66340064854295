<template>
  <div class="pageContol listWrap templateList">
    <div class="framePage">
      <div class="framePage-title">
        <span>
          <em>当前位置：</em>
          <a href="javascript:;">数据中心</a>
          <i>></i>
          <a href="javascript:;" @click="$router.back();">综合信息查询</a>
          <i>></i>
          <a href="javascript:;" class="cur-a">学习轨迹</a>
        </span>
      </div>
      <div class="framePage-body">
        <div class="framePage-body">
          <div class="framePage-scroll">
            <div class="ovy-a">
              <el-table ref="multipleTable" :data="tableData" :height="tableHeight" size="small" tooltip-effect="dark"
                style="width: 100%" stripe :header-cell-style="tableHeader">
                <el-table-column label="序号" align="center" type="index" :index="indexMethod" width="80px" />
                <el-table-column label="姓名" align="left" show-overflow-tooltip prop="userName" width="80px" />
                <el-table-column label="身份证号" align="left" show-overflow-tooltip prop="idcard" width="150px" />
                <el-table-column label="手机号" align="left" show-overflow-tooltip prop="mobile" width="100px" />
                <el-table-column label="学习日期" align="left" show-overflow-tooltip prop="startTimeStr" width="120px">
                  <template slot-scope="scope">
                    {{ scope.row.loginDate | momentDate }}
                  </template>
                </el-table-column>
                <el-table-column label="学习时间" align="left" prop="studyTime" width="150" />
                <el-table-column label="登录ip" align="left" show-overflow-tooltip prop="loginIP" width="120px" />
                <el-table-column label="学习内容" align="left" show-overflow-tooltip prop="learningContent" />
                <el-table-column label="有效学习时长" align="left" show-overflow-tooltip prop="effectiveLearningTime" width="120px" />
                <el-table-column label="学习累计时长" align="left" show-overflow-tooltip prop="cumulativeLearningTime" width="120px" />
                <Empty slot="empty" />
              </el-table>
            </div>
          </div>
          <!-- <PageNum :apiData="apiData" @sizeChange="sizeChange" @getData="getData" /> -->
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import Empty from "@/components/Empty.vue";
// import PageNum from "@/components/PageNum.vue";
import List from "@/mixins/List";
export default {
  name: "set_ciq_type_3_Info",
  components: {
    Empty,
    // PageNum,
  },
  mixins: [List],
  data() {
    return {

    };
  },
  computed: {

  },
  mounted() {
  },
  created() {

  },
  methods: {
    // 查询 - 列表
    getData(pageNum = 1) {
      const params = {
        pageNum: pageNum,
        pageSize: this.pageSize,
        projectId: this.$route.query.projectId,
        userId: this.$route.query.userId,
      };
      this.doFetch(
        {
          url: "/biz/project/getUserStudyTrack",
          params,
          pageNum,
        },
      );
    },
  },
};
</script>
<style lang="less" scoped></style>
